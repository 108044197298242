.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background-color: var(--orange);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;

}
.navbar-logo img:nth-child(1){
  height: 60px;
  opacity: 0.8;
}
.navbar-logo img:nth-child(2){
  height: 55px;
  opacity: 0.8;
  margin: 0 10px;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: center;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 0.9rem;
}

.nav-links:hover {
  /* background-color: #1888ff; */
  border-bottom: solid 3px brown;
  /* border-radius: 4px; */
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    position: relative;
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px; 
    height: 50px;
    /* background: white;  */
    border: none; 
    cursor: pointer;
    z-index: 999;
  }

  .menu-icon i {
    color: #fff;
    font-size: 1.8rem;
  }

  .nav-menu {
    display: flex;

    flex-direction: column;
    width: 70%;
    max-width:300px;
    height: 100vh;
    position: fixed;
    top:-19px;
    left: -100%;
    background-color: var(--orange);
    transition: left 0.3s ease;
    z-index: 998;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }

  .nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 0.9rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    /* background-color: #1888ff; */
    border-radius: 0;
  }
/* 
  .navbar-logo {
    color: #fff;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  } */

  .navbar-logo img:nth-child(1){
    height: 50px;
  }
  .navbar-logo img:nth-child(2){
    height: 35px;
  }

  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }

  .nav-links-mobile {
    display: none;
  }

  .fa-bars,
  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}

.back-button-div{
  margin-left: auto;
  }
.back-button {
  display: block;
  font-size: 24px;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: transparent;
  font-weight: 100;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
