html {
  overflow: hidden;
  height: 100%;

}

body {
  height: 100%;
  /* overflow: auto; */
  background-color: rgba(255,255,255,1);
}

body > div {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}


:root
{
    --yellow: #F5C32C;
    /* --orange : #FCA61F; */
    /* --black : #242D49; */
    --black : linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);

    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 167, 31, 0.358);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
    --darkPurple : #752298;
    /* --orange :#C87167; */
    --orange : darkcyan

}

.App{
    padding: 0;
    /* padding-top: 17vh; */
    overflow: hidden;
    color: white;    
    /* font-family: 'Assistant', sans-serif; */
    font-family: 'Noto Kufi Arabic', sans-serif;

  }

.button{
  border: solid 1px white;
  color: white;
  font-size: 24px;
  padding: 14px 24px;
  background: darkcyan;

}

.button:hover{
  background: white;
  cursor: pointer;
  color:var(--orange);
  border: 1px solid var(--orange);

}

@media screen and (max-width:480px) {
  .App{
    /* padding: 0.5rem 1rem; */
  }
}





.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* .home {
  background-image: url('./Components/images/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-image: url('./Components/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('./Components/images/img-3.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {
  background-image: url('./Components/images/img-4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./Components/images/img-7.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.marketing {
  background-image: url('./Components/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.consulting {
  background-image: url('./Components/images/img-5.jpg');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

   */

  