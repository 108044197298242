
.programs{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    /* margin-top: 15vh; */
    padding-top: 4rem;
    animation-name: slideUp;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

    .name {
      white-space: nowrap; /* Prevent text from wrapping */
      overflow: hidden; /* Hide overflow text */
      text-overflow: ellipsis; /* Display ellipsis (...) when text overflows */
      max-width: 100%; /* Set maximum width */
      cursor: pointer; /* Change cursor to indicate hover effect */
    }
    
    .name:hover {
      overflow: visible; /* Show overflow text on hover */
      white-space: normal; /* Allow text to wrap on hover */
      z-index: 999; /* Ensure tooltip is displayed above other elements */
      position: relative; /* Position tooltip relative to its parent */
      /* Additional styling for tooltip */
      background-color: #fff; /* Background color of tooltip */
      border: 1px solid #ccc; /* Border of tooltip */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect of tooltip */
      padding: 5px; /* Padding of tooltip */
    }
    
  
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .overlay-image {
    position: absolute;
    top: 40%;
    left: 35%;
    width: 30%;
    height: auto;
    object-fit: cover;
    z-index: 1;
    opacity: 0.9;
  }

/* .programs span:nth-of-type(1){
    color: black;
    font-size: 2rem;
    font-weight: bold;
}  */

.programs span:nth-of-type(1){
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}
.categoryName-programs{
    color: black;
    padding: 2rem;
    text-align: center;
    /* background-color:rgb(238, 238, 238);  */
       /* box-shadow : 0px 19px 60px rgb(0 0 0 / 8%); */


}


.m-programs{
    /* background: var(--black); */
    /* direction: rtl; */
}
.m-programs .swiper{
    overflow: visible!important;
    padding:  3rem 2rem;
    /* margin: 2rem; */
   /* box-shadow : 0px 19px 60px rgb(0 0 0 / 8%); */
}
.swiper-wrapper{
    align-items: flex-end;
}

.programs-slider{
    margin-top: 3rem;
    width: 100%;
}

.programs-slider .swiper-slide{
    width:20rem;
}

.programs-card .thumbnail-image{
    width: 20vw;
    max-width: 500px;
    min-width: 400px;
    height: 55vh;
    filter: drop-shadow(-12px 15px 13px rgba(0,0,0, 0.25));
    border-radius: 8px;
}

.programs-card{
    color: black;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;

}


  
  .image-container {
    position: relative;
    display: inline-block;
  }
  
  .price {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8); 
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold; 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  


@media screen and (max-width:480px) {
    .m-programs .swiper {
        padding: 0;
    }
    .swiper-wrapper{
        /* flex-direction: column; */
    }
  }
