

  .footer {
    background: var(--orange);
    color: whitesmoke;
}

  
  .f-icons{
    display: flex;
    gap: 0rem;
}


.email-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  background-color: darkslategray;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 90%; /* Adjust this value as needed */
  left: 1%;
  margin-left: -60px; /* Adjust this value to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.email-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}



.f-icons>*{
}
.f-icons a>*{

    margin: 1rem;
    font-size: 3rem;
    opacity: 0.8;
    color:  whitesmoke;
    border-radius: 50%;
}


.f-icons>*:hover{
cursor: pointer;
scale: 1.1;

}

.footer .f-content {

  background-color: var(--black);
  position: sticky;
  /* margin-top: 2rem; */
  padding:  2rem 0rem;
    gap: 2rem;
   transition: background-color 0.5s ease 0s;
   z-index: 2;
   box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 1.8rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   min-height: 63px;
}

.f-icons{
    display: flex;
    /* gap: 5px; */
}

.f-logo{
height: 7rem;
}

.experience-div{
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.experience-div>*{
  text-align: center;
  line-height: 1.8;
  font-size: 1.0rem;
  font-weight: 600;
  opacity: 0.9;
  width: 50%;
  justify-content: center;
}



@media screen and (max-width:480px) {
    .f-content>*{
        transform: scale(0.9);
    }

    .f-icons{
      transform: scale(0.7);

    }
    .f-icons>a*{
      font-size: 2rem;
    }
    .footer .f-content{
      gap:0;
    }

}