
.books{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    /* margin-top: 15vh; */
    padding-top: 4rem;
    animation-name: slideUp;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


.books span:nth-of-type(1){
    color: black;
    font-size: 2rem;
    font-weight: bold;
} 

.books span:nth-of-type(2){
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}
.categoryName-books{
    padding: 2rem;
    text-align: center;
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;

}


.m-books{
/* padding-bottom: 2rem; */
}
.m-books .swiper{
    overflow: visible!important;
    padding:  3rem 2rem;
    /* margin: 2rem; */
   box-shadow : 0px 19px 60px rgb(0 0 0 / 8%);
}
.swiper-wrapper{
    align-items: flex-end;
}

.books-slider{
    margin-top: 3rem;
    width: 100%;
}

.books-slider .swiper-slide{
    width:20rem;
}

.books-card img{
    width: 20vw;
    max-width: 350px;
    min-width: 200px;
    height: 50vh;
    filter: drop-shadow(-12px 15px 13px rgba(0,0,0, 0.25));
    border-radius: 8px;
}

.books-card , .name{
    color: black;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-align: right;
    font-weight: 600;
    margin-bottom: 1rem;
    position: relative;

}


  
  .image-container {
    position: relative;
    display: inline-block;
  }
  
  .price {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8); 
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold; 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .full-screen-view{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-direction: row;
    color: black;
    padding: 3rem;
    

    
  }

  .full-screen-view  img{
    max-height: 70vw;

  }

  .fs-Name{
    width: 100%;
    text-align: center ;
  }


.fs-right{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  
}
  .fs-Brief{
    font-size: 20px;
    opacity: 0.8;
  }

  .fs-Price{
    font-size: 24px;
    font-weight: 700;
    color: var(--orange);
  } 

  .main-fs{
    display: flex;
    flex-direction: column;
  }

  .All-suggested{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    flex-wrap: wrap;
    color: black;
    background-color: rgb(240, 240, 240);

  }

  .All-suggested-wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin: 1rem;

  }

  .suggested-items{
    display: flex;
    flex-direction: column;
    color: black;
    justify-content: center;
    align-items: center;
    
  }

  .suggested-items img{
    width: 20vw;
  }

  .suggested-item-link {
    text-decoration: none; 
    color: inherit;
    display: flex;
    align-items: flex-end;
  }

  .fs-whatsappButton {
    /* background-color: #25d366;  */
    background-color: #27914e; 
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 24px;
    cursor: pointer;
    animation: bounce 0.5s infinite alternate; 
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  

@media screen and (max-width:480px) {
    .m-books .swiper {
        padding: 0;
    }
    .swiper-wrapper{
        /* flex-direction: column; */
    }

    .full-screen-view {
      flex-direction: column;
    }

    .suggested-items img{
      width: 40vw;
    }

    
  }
