.works{
    padding: 3rem 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    /* height: 90vh; */

}

.w-right{
    position: relative;
}
.awesome-works{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
}

.awesome-works > :nth-child(1){
    color: black;
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome-works > :nth-child(2){
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome-works > :nth-child(3){
    color: var(--gray);
    font-size: 18px;
    margin: 2rem ;
}



.w-mainCircle{
    position: relative;
    width: 18rem;
    height: 18rem;
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    top: 2rem;
    background: white;
}

.w-mainCircle>*{
    position: absolute;
}

.w-mainCircle > :nth-child(1){
    top:-3rem;
    left: 6rem;
}

.w-mainCircle > :nth-child(2){
    left:-3rem;
    top: 5rem;
}

.w-mainCircle > :nth-child(3){
    left: 6rem;
    top: 5rem;
}

.w-mainCircle > :nth-child(4){
    left:15rem;
    top: 5rem;
}

.w-mainCircle > :nth-child(5){
    left:6rem;
    top: 13rem;
}

.w-secCircle{
    width: 6rem;
    height: 6rem;
    position: absolute;
    border-radius: 100%;
    border: 5px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--smboxShadow);
    background: white;
}

.w-secCircle > img{
    width: 80px;
    border-radius: 20px;}


.w-backCircle{
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    z-index: -1;
}

.s-button{
text-decoration: none;
}

/* .blueCircle{
    background: #194988;
    left: 18rem;
    top: 0rem;
}

.yellowCircle{
    background: #f5c32c;
    left: 18rem;
    top: 8rem;
} */

@media screen and (max-width:800px) {
    .works{
        flex-direction: column;
        height: 55rem;
        padding: 0;
        gap: 5rem;

    }
    .w-right{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        transform: scale(0.7);
    }
    .w-mainCircle{
        left: 0%;
        }
    .w-backCircle{
        left: 8rem !important;
    }

    .w-mainCircle > :nth-child(1){
        top:-3rem;
        left: 5rem;
    }
    
    .w-mainCircle > :nth-child(2){
        left:-4rem;
        top: 5.5rem;
    }
    
    .w-mainCircle > :nth-child(3){
        left: 5rem;
        top: 5.5rem;
    }
    
    .w-mainCircle > :nth-child(4){
        left:14rem;
        top: 5.5rem;
    }
    
    .w-mainCircle > :nth-child(5){
        left:5rem;
        top: 14rem;
    }
}