.testimonials-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: black;
    width:80vw;
}

.testimonial{
    display: flex;
    flex-direction: row;    
    justify-content: space-around;
    align-items: center;
    gap: 2rem;

}

.testimonial-name{
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;
}

.swiper-slide{
    margin-top: 5rem;
}


.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    opacity: 0.5;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    opacity: 0.5;
}

@media screen and (max-width:480px) {
    .testimonial{
    flex-direction: column;
    gap: 0;
    justify-content: space-around;
    height: 100vh;
    }
}