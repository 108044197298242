.AboutUsMain{
    color: black;
    margin: 8rem;
    min-height: 80vh;
}
.aboutus-image{
    width: 100%;
}

@media screen and (max-width:700px) {
    .AboutUsMain{
        margin: 0.5rem;
        margin-top: 2rem;
    }
}