.intro{
    display: flex;
    flex-direction: column;

}

.intro-intro {
    display: flex;
    flex-direction: row;
    /* height: 86vh; */
    animation-name: slideUp;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    /* background:var(--black); */
    box-shadow: inset 0 -10px 20px rgba(0, 0, 0, 0.4);
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  



.i-left{
    display: flex;
    position:relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
    padding: 2rem;
    align-items: center;
    text-align: center;
}

.i-name{
display: flex;
flex-direction: column;
align-items: center;
gap:  1rem;
color: black;
}

.i-name>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
    /* text-shadow: 2px 2px darkslateblue; */
    font-size: 2.6rem;
}

.i-name>:nth-child(2){
    font-weight: 100;
    font-size: 2.2rem;
    word-spacing: 10px;
    /* color: rgb(204, 204, 204); */
}

.i-name>:nth-child(3){
    font-weight: bold;
    font-size: 1.6rem;
}

.i-name>:nth-child(4){
    font-weight: bold;
    font-size: 1.4rem;
}


.i-name>:nth-child(5){
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8;
    text-align: justify;
}

.i-button{
}

.i-left a{
    width: fit-content;
}

.i-icons{
    display: flex;
    gap: 0rem;
}

.i-icons a>*{

    margin: 1rem;
    font-size: 3rem;
    /* border-radius: 50%; */
}

.i-icons>:nth-child(1){
color: #3b5998;
}

.i-icons>:nth-child(2){
    color: #FF0000;
    }


    .i-icons > :nth-child(3) {
        width: auto;
    }






.i-icons>*:hover{
cursor: pointer;
scale: 1.1;

}

.i-right{
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex: 2;  
    


}



.i-right > :nth-child(1) {
    display: flex ;
    height: 85vh;
    transition: transform 0.3s ease-in-out;
    border-radius: 50%;
    margin-top: 2vh;

  }
  

.blur{
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: #edd0ff ;
    filter: blur(72px);
    z-index: -9;
    top: - 18%;
    left: 56%;
}

.white-BG{
    background-color: white;
}

.testimonial-title{
    text-align: center;
}

.testimonial-title> :nth-child(1){
        color: black;
        font-size: 2.5rem;
        font-weight: bold;
    }
    
    .testimonial-title> :nth-child(2){
        color: var(--orange);
        font-size: 2.5rem;
        font-weight: bold;
    }


.intro-testimonial{
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:800px) {
    .intro{
    flex-direction: column;
    /* height: 64rem; */
    /* gap: 4rem; */
    }

    .intro-intro{
        flex-direction: column-reverse;
    }

    .i-right > :nth-child(1) {
        border-radius: 50%;
        width: 95%; 
        height: auto;
        margin-top: 2rem;
   }

    /* .i-right{
        transform: scale(0.8);
        left: -3rem;
    }
    .i-right .blur{
        display: none;
    }
    .floating-div:nth-of-type(1){
        top: -7rem !important;
    } */
  }