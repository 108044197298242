/* .t-wrapper{
    padding: 0 3rem 0 3rem ;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    color: darkslategray;
}

.t-heading{
    align-self: center;
    padding: 4rem;

}

.t-heading>*{
    font-size: 2rem;
    font-weight: bold;
}

.t-heading span:nth-last-of-type(2){
    color: var(--orange);
}

.t-blur1{
    left: 38rem;
    top: 16rem;
}

.t-blur2{
    left: 0rem;
    top: 9rem;
}

.blog{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    width: 30rem; 
    height: 12rem;
    background: rgba(255,255,255,0.26);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    cursor: pointer;

}

.blog>img{
width: 9rem;
height: 9rem;
border-radius: 100%;
}

.blog>span{
    color: var(--gray);
}

.t-wrapper .swiper{
    width: 100%;
    height: 100%;
}

.t-wrapper .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;

}

.t-wrapper .swiper-pagination-bullet-active{
    background: var(--orange);
}

.t-wrapper .swiper-pagination-bullet{
    background: var(--orange);
}

.featuredBlog{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    width: 60vw;
}
.featuredBlog img{
    border-radius: 15%;
    max-height: 80vh;
}

.featuredBlog span{
    font-size: 2rem;
    font-weight: 600;
}
.featuredBlog div{
    font-size: 18px;
    width: 100%;
}

.featuredBlog p{
    font-size: 16px;
    width: 100%;
    text-align: right;
}

@media screen and (max-width:480px) {
    .t-wrapper{
        padding: 0;
    }
    .blog>img{
        width: 70px;
        height: 70px;

    }
} */

.t-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem; 
    justify-content: center;
    align-items: center; 
}


.t-heading{
    text-align:  center;
    padding-top: 2rem;
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}

.blog-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.8rem;
    margin: 2rem 0;
    cursor: pointer;
  }
  
  .blog-card {
    width: calc(30% - 20px); 
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-image img {
    display: flex;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 2rem;

  }
  
  .card-content {
    font-size: 1.4rem;
    padding: 2rem;
    opacity: 0.7;
  }
  
  .fullscreen-blog{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.8rem;
    /* margin: 2rem 0; */
    font-size: 22px;
    background-color: rgba(241, 241, 241, 0.539);

    
  }

  .fs-blogText{
    margin:  0 10vw;
    max-width: 1000px;
    }

  @media screen and (max-width:680px) {

    .blog-card {
        width: calc(80%); 
    }
    
  }